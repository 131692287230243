import axios from 'axios';
import ApiConstants from '../../_Constants/ApiConstants';
import AppConstants from '../../_Constants/AppConstants';
import { aqtStore } from '../../Components/State/Store';
import { withHeader } from '../../Auth';
import { networkError, getSession, logToConsole } from '../../Util';


/**
 * Get all custom scenarios from controller API.
 */
export function fetchCustomScenarios() {
  if (getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }
  let controllerEndpoint = aqtStore.getState().environment.controllerEndpoint;
  return axios.get(`${controllerEndpoint}${ApiConstants.GET_CUSTOM_SCENARIOS}`,
    withHeader(aqtStore.getState().session.idToken.jwtToken))
    .then(scenarios => (scenarios.data || []))
    .catch(error => {
      networkError(error, fetchCustomScenarios.name);
      return {
        error: AppConstants.NETWORKERR
      };
    });
}

/**
 * Calls Create custom scenario POST API to create user defined test.
 * @param {object} customTestFormInput Custom test definition to be created.
 * @param {file} testDefinitionFile .csv test definition to be uploaded into S3
 * @param {file} audioFile .zip audio file to be uploaded into S3
 */
export function createCustomScenario(customTestFormInput, testDefinitionFile, audioFile) {
  let controllerEndpoint = aqtStore.getState().environment.controllerEndpoint;
  const formData = new FormData();
  
  formData.append("CustomScenario", JSON.stringify(customTestFormInput));
  
  if (testDefinitionFile) {
    // Create new File object with correct MIME type
    const file = new File(
      [testDefinitionFile],
      testDefinitionFile.name,
      { 
        type: testDefinitionFile.name.endsWith('.feature') 
          ? 'application/octet-stream'
          : testDefinitionFile.type
      }
    );
    formData.append("testDefinitionFile", file);
  }
  
  if (audioFile) {
    formData.append("audioFile", audioFile);
  }

  const config = {
    headers: { 
      "Content-Type": "multipart/form-data",
      Authorization: 'Bearer ' + aqtStore.getState().session.idToken.jwtToken 
    }
  };

  return axios.post(`${controllerEndpoint}${ApiConstants.GET_CUSTOM_SCENARIOS}`,
    formData, config)
    .then(customTestCreate => {
      if (customTestCreate.data) {
        logToConsole('Custom test create : ' + JSON.stringify(customTestCreate.data));
        return { success: customTestCreate.data };
      } else {
        return { error: AppConstants.SERVERERR }
      }
    })
    .catch(error => {
      networkError(error, createCustomScenario.name);
      return {
        error: AppConstants.NETWORKERR
      };
    });
}

/**
 * Calls custom scenario PUT API to update user defined test.
 * @param {object} customTestFormInput Custom test definition to be created.
 * @param {string} customScenarioId ID of the custom test being updated.
 * @param {file} testDefinitionFile .csv test definition to be uploaded into S3
 * @param {file} audioFile .zip audio file to be uploaded into S3
 */
export function updateCustomScenario(customTestFormInput, customScenarioId, testDefinitionFile, audioFile) {
  let controllerEndpoint = aqtStore.getState().environment.controllerEndpoint;
  const formData = new FormData();
  formData.append("CustomScenario", JSON.stringify(customTestFormInput));
  if (testDefinitionFile) {
    formData.append("testDefinitionFile", testDefinitionFile);
  }
  if (audioFile) {
    formData.append("audioFile", audioFile);
  }
  const config = {
    headers: { "Content-Type": "multipart/form-data", 
                Authorization: 'Bearer ' + aqtStore.getState().session.idToken.jwtToken }
  }
  return axios.put(`${controllerEndpoint}${ApiConstants.GET_CUSTOM_SCENARIOS}/${customScenarioId}`,
    formData, config)
    .then(customTestCreate => {
      if (customTestCreate.data) {
        logToConsole('Custom test update : ' + JSON.stringify(customTestCreate.data));
        return { success: customTestCreate.data };
      } else {
        return { error: AppConstants.SERVERERR }
      }
    })
    .catch(error => {
      networkError(error, updateCustomScenario.name);
      return {
        error: AppConstants.NETWORKERR
      };
    });
}

/**
 * Calls delete custom scenario API to erase the custom test
 * @param {string} customScenarioId ID of the custom test being deleted.
 */
export function deleteCustomScenario(customScenarioId) {
  let controllerEndpoint = aqtStore.getState().environment.controllerEndpoint;
  const config = {
    headers: { "Content-Type": "multipart/form-data", 
                Authorization: 'Bearer ' + aqtStore.getState().session.idToken.jwtToken }
  }
  return axios.delete(`${controllerEndpoint}${ApiConstants.GET_CUSTOM_SCENARIOS}/${customScenarioId}`, config)
    .then(customTestDelete => {
      if (customTestDelete.data) {
        logToConsole('Custom test delete : ' + JSON.stringify(customTestDelete.data));
        return { success: customTestDelete.data };
      } else {
        return { error: AppConstants.SERVERERR }
      }
    })
    .catch(error => {
      networkError(error, deleteCustomScenario.name);
      return {
        error: AppConstants.NETWORKERR
      };
    });
}
